/*
	DESCRIPTION / USAGE:
		Global CSS file

	TODO:

*/

/* Import Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiCard-root {
  background-image: none !important;
}

.flip_horizontal {
  transform: scaleX(-1);
}

.MuiInputLabel-formControl {
  z-index: 0;
}

.ql-snow .ql-stroke {
  stroke: #fff;
}

.ql-snow .ql-picker {
  color: #fff;
}

.ql-snow .ql-picker-options {
  background: #272b33;
}

.ql-editor {
  min-height: 300px;
}

.ql-scroll-html-render h1,
.ql-scroll-html-render h2,
.ql-scroll-html-render h3,
.ql-scroll-html-render p,
.ql-scroll-html-render ol,
.ql-scroll-html-render ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.etw_chat_message_container {
  height: calc(100vh - 300px) !important;
  overflow: hidden;
}

.etw_chat_threads_container {
  height: calc(100vh - 250px) !important;
  overflow: scroll;
}

.etw_chat_message_display {
  height: calc(100vh - 400px) !important;
  overflow-y: scroll;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}
